import styled from 'styled-components'
import error from 'assets/images/404.png'
import { Row } from 'components/atoms/Row'
import { Column } from 'components/atoms/Column'
import { Text } from 'components/atoms/Text'
import Image from 'next/image'

export default function Custom404() {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Image src={error} alt="404 error" width={380} height={150} />
        <ErrorText variant="title2" bold>
          Error 404. The page you are looking for doesn&#39;t exist or has been moved.
        </ErrorText>
      </ContentWrapper>
    </PageWrapper>
  )
}

const PageWrapper = styled(Row)`
  justify-content: center;
  height: 100%;
  min-height: inherit;
  padding: 80px 24px;
  background: ${({ theme }) => theme.colors.White};
`

const ContentWrapper = styled(Column)`
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 442px;
  gap: 80px;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    gap: 48px;
  }
`

const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.CapeCod};
  text-align: center;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    font-size: 16px;
    line-height: 24px;
  }
`
